import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import Calendar from "../components/microcomponenets/Calendar";
import { useEffect } from "react";
import { useState } from "react";

import star from "../static/appt/Star.svg";
import dlr from "../static/appt/dollar.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import AppSlots from "../components/appt/AppSlots";
import {
  CheckIfDoctorAvailable,
  BookAppointmentAPI,
  GetAppointmentTotalAPI,
  GenerateTokenAPI,
  FreezeSlotAPI
} from "../services/apptservice";
import {
  RazorpayAPI,
  displayRazorpay
}
  from "../services/razorpayservice";

import swal from "sweetalert";
import Swal from "sweetalert2";
import Dependents from "../components/appt/Dependents";
import PaymentSummary from "../components/appt/PaymentSummary";
import AppSummary from "../components/appt/AppSummary";
import { useLayoutEffect } from "react";
import { RescheduleAppointment } from "../services/apptservice";
import GenProfileUpdate from "../components/appt/GenProfileUpdate";
import AnimatedConfirm from "../components/microcomponenets/AnimatedConfirm";

import dependent from "../static/appt/dep.svg";

import vc from "../static/appt/bka-vc.svg";
import ip from "../static/appt/bka-ip.svg";
import clk from "../static/appt/bka-clk.svg";
import cal from "../static/appt/bka-cal.svg";
import PaymentMethods from "../components/appt/PaymentMethods";
import Calendar2 from "../components/microcomponenets/Calendar2";
import NewWindow from "react-new-window";
import useDocumentTitle from "../utils/useDocumentTitle";
import ApptType from "../components/appt/ApptType";
import { PhonePeAPI, displayPhonePe } from "../services/phonepeservice";

const DocAvailObj = {
  doctorId: 0,
  ScheduleDateAtLocalTime: moment().format(),
  TimeDifferenceFromUtcInMinutes: moment().utcOffset(),
  type: "",
  processId: 0,
};

function BookAppointment() {
  useDocumentTitle("Book Appointment");
  const location = useLocation();

  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);

  const [doctorObj, setDoctorObj] = useState(location.state?.doctor);
  const [apptType, setApptType] = useState(
    location.state?.apptType
      ? location.state?.apptType
      : clinicDetails.hasVideoCallAppointment
        ? "InPerson"
        : clinicDetails.hasVideoCallAppointment
          ? "VideoCall"
          : ""
  );

  const [isToBeRescheduled, setIsToBeRescheduled] = useState(
    location.state?.reschedule ? location.state?.reschedule : false
  );

  //followup things
  const [isFollowUp, setIsFollowUp] = useState(
    location.state?.followup ? location.state?.followup : false
  );
  const isFreeFollowUp = location.state?.followup
    ? location.state?.followup
    : false;

  //overall page state
  const [pageState, setPageState] = useState({});

  const user = useSelector((state) => state.user);

  const utoken = user.userObj.token;
  //slots array

  const [slots, setSlots] = useState({ slotlist: [], isLoading: false });
  const [dayInfo, setDayInfo] = useState({
    day: "",
    date: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.doctor) {
      navigate("/");
    } else if (user.userObj === null) {
      navigate("/selectdoctor");
    } else {
      let tempDocAvailObj = {
        ...DocAvailObj,
        doctorId: doctorObj.id,
        type: location.state?.apptType
          ? location.state?.apptType
          : clinicDetails.hasVideoCallAppointment
            ? "InPerson"
            : clinicDetails.hasVideoCallAppointment
              ? "VideoCall"
              : "",
        processId: location.state?.processId ? location.state?.processId : 0,
      };

      if (location.state.dateTimeObj) {
        tempDocAvailObj = {
          ...tempDocAvailObj,
          ScheduleDateAtLocalTime: location.state.dateTimeObj,
        };

        setDayInfo({
          day: moment(location.state.dateTimeObj).utc().local().format("dddd"),
          date: moment(location.state.dateTimeObj)
            .utc()
            .local()
            .format("DD MMMM YYYY"),
        });
      } else {
        setDayInfo({
          day: moment().format("dddd"),
          date: moment().format("DD MMMM YYYY"),
        });
      }

      callCheckIfDoctorAvailable(tempDocAvailObj);
    }
  }, []);

  // Function For ON DATE CHANGE
  const onDateChange = (date) => {
    let tempDocAvailObj = {
      ...DocAvailObj,
      ...pageState,
      ScheduleDateAtLocalTime: date,
    };

    setDayInfo({
      day: moment(date).format("dddd"),
      date: moment(date).format("DD MMMM YYYY"),
    });

    callCheckIfDoctorAvailable(tempDocAvailObj);
  };

  //Generic API CALL
  const callCheckIfDoctorAvailable = (tempDocAvailObj) => {
    setSlots({ ...slots, isLoading: true });
    CheckIfDoctorAvailable(tempDocAvailObj)
      .then((res) => {
        if (res.status === "Success") {
          setSlots({ slotlist: res.response.slots, isLoading: false });
          setPaymentDetails({
            ...res.response.paymentDetails,
          });
        }
        setPageState({ ...tempDocAvailObj });
      })
      .catch((err) => {
        if (err.response.data.status === "Fail") {
          setSlots({ slotlist: [], isLoading: false });

          setPageState({ ...tempDocAvailObj });
          setSelectedSlot("");
          setEndSelectedSlot("");
        }
      });
  };

  //getDateFromCalandar
  const getDateFromCalandar = (date) => {
    onDateChange(date);
  };

  //appt type change
  const onApptTypeChange = (selectedType) => {
    let tempDocAvailObj = {
      ...DocAvailObj,
      ...pageState,
      type: selectedType,
    };

    setApptType(selectedType);

    callCheckIfDoctorAvailable(tempDocAvailObj);
  };

  // selectedSlot
  const [selectedSlot, setSelectedSlot] = useState(location.state?.timeslot?.startAtUtc ? location.state?.timeslot?.startAtUtc : "");
  const [endselectedSlot, setEndSelectedSlot] = useState(location.state?.timeslot?.endAtUtc ? location.state?.timeslot?.endAtUtc : "");
  const [ispageOneActive, setIspageOneActive] = useState(true);
  const [isGenDrawerOpen, setIsGenDrawerOpen] = useState(false);

  // change to confirm booking 
  useEffect(() => {
    if (location.state?.processToPayment === true && location.state?.timeslot) {
      if (apptType === "") {
        swal({
          icon: "error",
          title: "No Appointment Type Selected",
          text: "Please Select an Appointment Type",
        });
      } else {
        if (selectedSlot === "") {
          swal({
            icon: "error",
            title: "No Slot Selected",
            text: "Please Select a Slot",
          });
        } else {
          if (
            user.userObj.patientData.dob === null ||
            user.userObj.patientData.dob === "" ||
            user.userObj.patientData.sex === null ||
            user.userObj.patientData.sex === ""
          ) {
            setIsGenDrawerOpen(true);
          } else {
            setIspageOneActive(false);
          }
        }
      }
    }
  }, [location.state])

  // go to page 2
  const goTOpageTwo = () => {
    console.log(selectedSlot, "check on function");
    if (apptType === "") {
      swal({
        icon: "error",
        title: "No Appointment Type Selected",
        text: "Please Select an Appointment Type",
      });
    } else {
      if (selectedSlot === "") {
        swal({
          icon: "error",
          title: "No Slot Selected",
          text: "Please Select a Slot",
        });
      } else {
        if (
          user.userObj.patientData.dob === null ||
          user.userObj.patientData.dob === "" ||
          user.userObj.patientData.sex === null ||
          user.userObj.patientData.sex === ""
        ) {
          setIsGenDrawerOpen(true);
        } else {
          setIspageOneActive(false);
        }
      }
    }
  };


  //depandants logic

  const [dependant, setDependant] = useState(0);

  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const paymentMethodList = useSelector(
    (state) => state.clinic.clincObj.avaiablePaymentMethods
  );

  //payment summary
  const [paymentDetails, setPaymentDetails] = useState({
    paymentType: location?.state?.paymentDetails?.paymentType ? location?.state?.paymentDetails?.paymentType : paymentMethodList[0]?.systemName,
    fee: location?.state?.paymentDetails?.fee ? location?.state?.paymentDetails?.fee : 0,
    tax: location?.state?.paymentDetails?.tax ? location?.state?.paymentDetails?.tax : 0,
    serviceFee: location?.state?.paymentDetails?.serviceFee ? location?.state?.paymentDetails?.serviceFee : 0,
  });

  //conform booking Logic

  const [isAppointmentBooked, setIsAppointmentBooked] = useState(false);
  const [confirmBookingLoader, setConfirmBookingLoader] = useState(false);

  //appt ID
  const apptIDForReschedule = location.state?.apptId;

  const [bookedState, setBookedState] = useState(null);

  const confirmBooking = () => {
    setConfirmBookingLoader(true);

    if (
      user.userObj.patientData.appointmentCountryCode === null ||
      user.userObj.patientData.appointmentCountryCode === "" ||
      user.userObj.patientData.appointmentPhone === null ||
      user.userObj.patientData.appointmentPhone === ""
    ) {
      swal({
        icon: "error",
        title: "Incomplete Phone Information",
        text: "Please enter your phone number.",
      }).then(() => {
        // Run your function here
        setIsGenDrawerOpen(true);
        setConfirmBookingLoader(false);
      });
      return;
    }

    if (isToBeRescheduled) {
      let tempObj = {
        appointmentId: apptIDForReschedule,
        date: selectedSlot,
        cancelledBy: "Patient",
        cancelledById: user.userObj.patientData.id,
        timeDifferenceFromUtcInMinutes: moment().utcOffset(),
      };

      RescheduleAppointment(tempObj, utoken)
        .then((res) => {
          if (res.status === "Success") {
            // setIsAppointmentBooked(true);
            setConfirmBookingLoader(false);
            //(res.response.appointment);

            Swal.fire({
              icon: "success",
              title: "Done",
              text: "Your Appointment has been rescheduled",
              allowOutsideClick: false,
              timer: 5000,
              customClass: {
                confirmButton: "btnr btnr-primary",
              },
            }).then((result) => {
              navigate(`/appointmentsDetails/${tempObj.appointmentId}`);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setConfirmBookingLoader(false);
          swal({
            icon: "error",
            title: err?.response?.data?.response?.message,
            text: "Please try again",
          });
        });
    } else if (isFollowUp) {
      let tempObj = {
        scheduledAtUtc: selectedSlot,
        doctorId: doctorObj.id,
        patientId: user.userObj.patientData.id,
        type: apptType,
        isFollowUp: true,
        followupAppointmentId: apptIDForReschedule,
        treatmentId: location.state?.processId ? location.state?.processId : 0,
        appointmentForId: dependant,
        paymentDetails: {
          ...paymentDetails,
        },
      };

      BookAppointmentAPI(tempObj, utoken)
        .then((res) => {
          if (res.status === "Success") {
            setIsAppointmentBooked(true);
            setConfirmBookingLoader(false);
            setBookedState(res.response.appointment);
          }
        })
        .catch((err) => {
          console.log(err);
          setConfirmBookingLoader(false);
          swal({
            icon: "error",
            title: err?.response?.data?.response?.message,
            text: "Please try again",
          });
        });
    } else {
      let tempObj = {
        scheduledAtUtc: location.state?.timeslot?.startAtUtc ? location.state?.timeslot?.startAtUtc : selectedSlot,
        doctorId: doctorObj.id,
        patientId: user.userObj.patientData.id,
        type: apptType,
        isFollowUp: false,
        treatmentId: location.state?.processId ? location.state?.processId : 0,
        appointmentForId: dependant,
        paymentDetails: {
          ...paymentDetails,
        },
      };

      if (paymentDetails.paymentType === "Payment.MPesa") {
        setConfirmBookingLoader(false);
        let getApptTotalsObj = {
          doctorId: doctorObj.id,
          patientId: user.userObj.patientData.id,
          type: apptType,
          isFollowUp: false,
          processId: location.state?.processId ? location.state?.processId : 0,
          paymentType: "Payment.MPesa",
        };

        GetAppointmentTotalAPI(getApptTotalsObj, utoken)
          .then((res) => {
            if (res.status === "Success") {
              setConfirmBookingLoader(false);
              let tokenObj = {
                paymentMethodSystemName: "Payment.MPesa",
                doctorId: doctorObj.id,
                type: apptType,
                processId: location.state?.processId
                  ? location.state?.processId
                  : 0,
                isFollowUp: false,
              };
              GenerateTokenAPI(tokenObj, utoken)
                .then((data) => {
                  if (data.status === "Success") {
                    //Freeze The Slot For 5 minutes
                    FreezeTheSlot(doctorObj.id, apptType, selectedSlot);
                    createPopup(data.response.paymentUrl);
                  }
                })
                .catch((err) => { });
            }
          })
          .catch((err) => {
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response?.data?.response?.message,
              text: "Please try again",
            });
          });

        // swal({
        //   icon: "error",
        //   title: "Functionalty in Progress",

        // })
      } else if (paymentDetails.paymentType === "Payment.RazorPay") {
        setConfirmBookingLoader(true);
        let getApptTotalsObj = {
          doctorId: doctorObj.id,
          patientId: user.userObj.patientData.id,
          type: apptType,
          isFollowUp: false,
          processId: location.state?.processId ? location.state?.processId : 0,
          paymentMethodSystemName: "Payment.RazorPay",
        };

        GetAppointmentTotalAPI(getApptTotalsObj, utoken)
          .then((res) => {
            if (res.status === "Success") {
              let tokenObj = {
                paymentMethodSystemName: "Payment.RazorPay",
                doctorId: doctorObj.id,
                type: apptType,
                processId: location.state?.processId ? location.state?.processId : 0,
                isFollowUp: false,
              };
              RazorpayAPI(tokenObj, utoken)
                .then((data) => {
                  setConfirmBookingLoader(false);
                  if (data.status === "Success") {
                    var razorpayPopupOptions = {
                      name: user.userObj.patientData.name,
                      email: user.userObj.patientData.email,
                      contact: user.userObj.patientData.phoneNumber,
                      order_id: data.response.id,
                      ...data.response
                    }

                    //Freeze The Slot For 5 minutes
                    FreezeTheSlot(doctorObj.id, apptType, selectedSlot);

                    displayRazorpay(razorpayPopupOptions, async (razorPayOrder) => {
                      setConfirmBookingLoader(true);
                      let razorOrderRequestBody = {
                        ...tempObj,
                        paymentDetails: {
                          ...tempObj.paymentDetails,
                          razorPayOrder: razorPayOrder
                        }
                      }

                      BookAppointmentAPI(razorOrderRequestBody, utoken)
                        .then((res) => {
                          if (res.status === "Success") {
                            setIsAppointmentBooked(true);
                            setConfirmBookingLoader(false);
                            setBookedState(res.response.appointment);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setConfirmBookingLoader(false);

                          var errMsg = '';

                          try {
                            if (typeof err === undefined) {
                              errMsg += "Lost connection with the server.";
                            } else if (typeof err.response?.data === 'string') {
                              errMsg += err.response.data;
                            } else if (typeof err?.response?.data?.response?.message === 'string') {
                              errMsg += err.response?.data?.response?.message;
                            } else {
                              errMsg += "Could not book the appointment";
                            }
                          }
                          catch {
                            errMsg = "Lost connection with the server.";
                          }

                          swal({
                            icon: "error",
                            title: errMsg,
                            text: "Please try again",
                          });
                        });
                    }, async (err) => {
                      setConfirmBookingLoader(false);
                      console.log('Razorpay Checkout Canceled', err);
                      swal({
                        icon: "error",
                        title: "Transaction was canceled",
                        text: "Transaction was canceled from Razorpay Client. Please try again",
                      });
                    })
                  }
                })
                .catch((err) => {
                  setConfirmBookingLoader(false);
                  console.log(err);
                  alert("Error while trying to proceed for RazorPay");
                });
            }
          })
          .catch((err) => {
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response.data?.response?.message,
              text: "Please try again",
            });
          });
      } else if (paymentDetails.paymentType === "Payment.PhonePe") {
        // 18-03
        setConfirmBookingLoader(true);
        let getApptTotalsObj = {
          doctorId: doctorObj.id,
          patientId: user.userObj.patientData.id,
          type: apptType,
          isFollowUp: false,
          processId: location.state?.processId ? location.state?.processId : 0,
          paymentMethodSystemName: "Payment.RazorPay",
        };
        GetAppointmentTotalAPI(getApptTotalsObj, utoken)
          .then((res) => {
            console.log(res, "RESPONS");

            if (res.status === "Success") {
              let phonepeDetails = {
                orderId: res?.response?.paymentDetails?.nextAppointmentGuid,
                amount:
                  paymentDetails.fee +
                  paymentDetails.tax +
                  paymentDetails.serviceFee,
                patientId: user.userObj.patientData.id,
                clinicId: res?.response?.paymentDetails?.clinicId,
              };
              console.log(
                res?.response?.paymentDetails?.nextAppointmentGuid?.replaceAll(
                  "-",
                  ""
                )
              );
              PhonePeAPI(phonepeDetails, utoken)
                .then((data) => {
                  setConfirmBookingLoader(false);
                  console.log(data);
                  const tokenUrl = data?.response?.redirectUrl;
                  // return;
                  if (data.status === "Success") {
                    let phonePeOrderRequestBody = {
                      ...tempObj,
                      paymentDetails: {
                        ...tempObj.paymentDetails,
                        phonePeOrder: {
                          merchantTransactionId:
                          data?.response?.merchantTransactionId
                          // res?.response?.paymentDetails?.nextAppointmentGuid?.replaceAll(
                          //   "-",
                          //   ""
                          // ),
                        },
                      },
                    };

                    //Freeze The Slot For 5 minutes
                    FreezeTheSlot(doctorObj.id, apptType, selectedSlot);

                    function error() {
                      swal({
                        icon: "error",
                        title: "Transaction was cancelled",
                        text: "Transaction was cancelled from PhonePe Client. Please try again",
                      });
                    }
                    function bookMyAppointment() {
                      setConfirmBookingLoader(true);
                      BookAppointmentAPI(phonePeOrderRequestBody, utoken)
                        .then((res) => {
                          if (res.status === "Success") {
                            setIsAppointmentBooked(true);
                            localStorage.removeItem("general-appointmentState");
                            setConfirmBookingLoader(false);
                            setBookedState(res.response.appointment);
                            setConfirmBookingLoader(false);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setConfirmBookingLoader(false);

                          var errMsg = "";

                          try {
                            if (typeof err === undefined) {
                              errMsg += "Lost connection with the server.";
                            } else if (typeof err.response?.data === "string") {
                              errMsg += err.response.data;
                            } else if (
                              typeof err?.response?.data?.response?.message ===
                              "string"
                            ) {
                              errMsg += err.response?.data?.response?.message;
                            } else {
                              errMsg += "Could not book the appointment";
                            }
                          } catch {
                            errMsg = "Lost connection with the server.";
                          }

                          swal({
                            icon: "error",
                            title: errMsg,
                            text: "Please try again",
                          });
                        });
                    }

                    displayPhonePe(tokenUrl, error, bookMyAppointment);
                  }
                })
                .catch((err) => {
                  setConfirmBookingLoader(false);
                  console.log(err);
                  alert("Error while trying to proceed for RazorPay");
                });
            }
          })
          .catch((err) => {
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response.data?.response?.message,
              text: "Please try again",
            });
          })
      } else {
        BookAppointmentAPI(tempObj, utoken)
          .then((res) => {
            if (res.status === "Success") {
              setIsAppointmentBooked(true);
              setConfirmBookingLoader(false);
              setBookedState(res.response.appointment);
            }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response?.data?.response?.message);
            setConfirmBookingLoader(false);
            swal({
              icon: "error",
              title: err.response?.data?.response?.message,
              text: "Please try again",
            });
          });
      }
    }
  };

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [ispageOneActive, isAppointmentBooked]);

  const processObj = location.state?.processObj
    ? location.state?.processObj
    : null;

  const navigateToUploadReports = (stateforNextPage) => {
    let newState = {
      id: 0,
      appointmentId: bookedState.id,
      reasonForVisit: "",
      attachments: [],
    };

    navigate("/uploadreports", {
      state: newState,
    });

    //check whether user is Logged in ??
  };

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const handleMessage = (e) => {
    console.log(e);

    if (typeof e.data === "string" || e.data instanceof String) {
      if (JSON.parse(e.data).token) {
        // console.log(JSON.parse(e.data).token);
        // setCreateOrderItems({
        //   ...createOrderItems,
        //   paymentToken: JSON.parse(e.data).token,
        // });

        setIsPopupActive(false);
      }
    }

    window.removeEventListener("message", handleMessage);
  };

  const FreezeTheSlot = async (doctorId, type, scheduledAtUtc) => {
    let requestBody = {
      doctorId: doctorId,
      type: type,
      scheduledAtUtc: scheduledAtUtc,
    };

    console.log('Freeze Slot Request Body', requestBody)
    FreezeSlotAPI(requestBody, utoken).then((data) => {
      console.log('Slot Reserved for 5 minutes', data);
    }).catch((err) => {
      console.error('Unable to freeze slot', err);
    });
  }

  useEffect(() => {
    if (paymentUrl.length > 0) {
      setIsPopupActive(true);
      window.addEventListener("message", handleMessage);
    }
  }, [paymentUrl]);

  const createPopup = (getUrl) => {
    let url = `https://${getUrl}`;

    setPaymentUrl(url);
  };

  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  const handleGoBack = () => {
    navigate(-1);

    if (window.history.length <= 1) {
      navigate('/');
    }
  };

  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );

  return (
    user.isSuccess && (
      <div className="main-body">
        <div className="container">

          <div className="walllist top-link">
            <span className="icon-button-left-line" onClick={handleGoBack}></span>
            Back
          </div>
          <div className="appt-main">
            {!isAppointmentBooked && (
              <div className="bk-grid">
                <div className={`bkgleft ${ispageOneActive ? "" : "hide"}`}>
                  <div className="d-sm-none d-block hus">Select Doctor</div>
                  <div className="appt-doc-profile">
                    <div className="img">
                      <img src={doctorObj?.pictureUrl} />
                    </div>
                    <div className="body">
                      <div className="name">{doctorObj?.name}</div>
                      <div className="designation">{doctorObj?.title}</div>
                      <div className="qualification">
                        {doctorObj?.qualification}
                      </div>
                      {
                        ispageOneActive && (
                          <>
                            {(doctorObj?.isReviewActive && doctorObj.rating >= 0) && (
                              <div className="icon-text">
                                <div className="icon">
                                  <img className="star" src={star} alt="star" />
                                </div>
                                <div className="txt">
                                  <span>Rating</span>
                                  {doctorObj.rating.toFixed(2)} out of 5
                                </div>
                              </div>
                            )}
                          </>
                        )
                      }


                      {apptType !== "" &&
                        (doctorObj?.inPersonConsultationFee ||
                          doctorObj?.videoCallConsultationFee ||
                          processObj?.price) && (
                          <>
                            <div className="maindocstats d-none d-sm-flex">
                              <div>
                                <span className="s1">
                                  {" "}
                                  {apptType === "InPerson" &&
                                    doctorObj?.inPersonConsultationFee &&
                                    "" +
                                    currencySymbol +
                                    doctorObj?.inPersonConsultationFee}
                                  {apptType === "VideoCall" &&
                                    doctorObj?.videoCallConsultationFee &&
                                    "" +
                                    currencySymbol +
                                    doctorObj?.videoCallConsultationFee}
                                  {apptType === "Treatment" &&
                                    processObj?.price &&
                                    "" + currencySymbol + processObj?.price}
                                </span>
                                <span className="s2">Consultation Fee</span>
                              </div>
                              <div className="mw">

                              </div>
                              <div>
                                <span className="s1">
                                  {" "}
                                  {apptType === "InPerson" &&
                                    doctorObj?.inPersonConsultationFee &&
                                    "" +

                                    doctorObj?.inPersonTime + "min"}
                                  {apptType === "VideoCall" &&
                                    doctorObj?.videoCallConsultationFee &&
                                    "" +
                                    doctorObj?.inPersonTime + "min"}
                                  {apptType === "Treatment" &&
                                    processObj.price &&
                                    "-min"}
                                </span>
                                <span className="s2">Duration</span>
                              </div>
                            </div>
                          </>
                        )}
                    </div>

                  </div>
                  {apptType !== "" &&
                    (doctorObj?.inPersonConsultationFee ||
                      doctorObj?.videoCallConsultationFee ||
                      processObj?.price) && (
                      <>
                        <div className="maindocstats d-flex d-sm-none">
                          <div>
                            <span className="s1">
                              {" "}
                              {apptType === "InPerson" &&
                                doctorObj?.inPersonConsultationFee &&
                                "" +
                                currencySymbol +
                                doctorObj?.inPersonConsultationFee}
                              {apptType === "VideoCall" &&
                                doctorObj?.videoCallConsultationFee &&
                                "" +
                                currencySymbol +
                                doctorObj?.videoCallConsultationFee}
                              {apptType === "Treatment" &&
                                processObj?.price &&
                                "" + currencySymbol + processObj?.price}
                            </span>
                            <span className="s2">Consultation Fee</span>
                          </div>
                          <div className="mw">

                          </div>
                          <div>
                            <span className="s1">
                              {" "}
                              {apptType === "InPerson" &&
                                doctorObj?.inPersonConsultationFee &&
                                "" +

                                doctorObj.inPersonTime + "min"}
                              {apptType === "VideoCall" &&
                                doctorObj?.videoCallConsultationFee &&
                                "" +
                                doctorObj?.inPersonTime + "min"}
                              {apptType === "Treatment" &&
                                processObj.price &&
                                "-min"}
                            </span>
                            <span className="s2">Duration</span>
                          </div>
                        </div>
                      </>
                    )}
                </div>
                {ispageOneActive && (
                  <>
                    <div className="bkgcenter addmargin">
                      {/* <div
                        className={` d-flex appt-cal ${isFollowUp ? "disabled" : ""}`}
                      >
                        <Calendar outputDate={getDateFromCalandar} />
                        
                      </div> */}
                      <div
                        className={` d-flex appt-cal ${isFollowUp ? "disabled" : ""
                          }`}
                      >
                        <Calendar2
                          inputDate={location.state.dateTimeObj}
                          outputDate={getDateFromCalandar}
                        />
                      </div>
                    </div>
                    <div className="bkgright">
                      <ApptType
                        apptType={apptType}
                        processObj={processObj}
                        outPutFn={(selectedType) => {
                          onApptTypeChange(selectedType);
                        }}
                        reschedule={isToBeRescheduled}
                        followUP={isFollowUp}
                        inPersone={doctorObj?.hasInPersonAppointmentEnabled}
                        videoCall={doctorObj?.hasVideoCallAppointmentEnabled}
                      />
                      <AppSlots
                        slots={slots.slotlist}
                        isLoading={slots.isLoading}
                        dayInfo={dayInfo}
                        opSlot={(slot, eslot) => {
                          setSelectedSlot(slot);
                          setEndSelectedSlot(eslot);
                        }}
                      />
                      <div className="d-flex justify-content-center proceed">
                        <button
                          className="btnr btnr-primary btnr-hover"
                          onClick={() => goTOpageTwo()}
                          style={{ padding: "15px 30px" }}
                        >
                          proceed to book
                          <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                    <GenProfileUpdate
                      setIspageOneActive={setIspageOneActive}
                      user={user}
                      isGenDrawerOpen={isGenDrawerOpen}
                      setIsGenDrawerOpen={setIsGenDrawerOpen}
                    />
                  </>
                )}
                {!ispageOneActive && (
                  <>
                    <div className="bkgcenter">
                      <div
                        className={`${!isToBeRescheduled && !isFollowUp ? "" : "disabled"
                          } apt-dependents`}
                      >
                        <Dependents
                          user={user}
                          outputDependantId={(depId) => setDependant(depId)}
                        />
                      </div>
                      <AppSummary
                        date={dayInfo.date}
                        endselectedSlot={endselectedSlot}
                        selectedSlot={selectedSlot}
                        apptType={apptType}
                        processObj={processObj}
                        clinicObj={clinicObj}
                        defaultHealthCenter={defaultHealthCenter}
                      />
                    </div>
                    <div
                      className={`bkgright ${ispageOneActive ? "" : "hide"}`}
                    >
                      <PaymentSummary
                        paymentDetails={paymentDetails}
                        setPaymentDetails={setPaymentDetails}
                        apptType={apptType}
                        doctorObj={doctorObj}
                        processObj={processObj}
                        isFreeFollowUp={isFreeFollowUp}
                        isFollowUp={isFollowUp}
                      />

                      <PaymentMethods
                        setPaymentDetails={setPaymentDetails}
                        paymentDetails={paymentDetails}
                        apptType={apptType}
                      />

                      <div className="d-flex justify-content-center">
                        <button
                          disabled={confirmBookingLoader}
                          className="btnr btnr-primary btnr-hover"
                          onClick={() => confirmBooking()}
                          style={{ padding: "15px 30px" }}
                        >
                          {confirmBookingLoader && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          )}
                          Confirm Booking
                          <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                    <GenProfileUpdate
                      setIspageOneActive={setIspageOneActive}
                      user={user}
                      isGenDrawerOpen={isGenDrawerOpen}
                      setIsGenDrawerOpen={setIsGenDrawerOpen}
                    />

                  </>
                )}
              </div>
            )}
            {isAppointmentBooked && (
              <div className="bk-grid">
                <div className="bkgleft d-none d-sm-block">
                </div>
                <div className="bkgcenter addmargin">
                  <div className=" apptbkd">
                    <div className="appt-det-parent">
                      <div className="animation">
                        <AnimatedConfirm />
                      </div>
                      <div className="tit">Booking Confirmed</div>
                      <div className="details">
                        <div className="title">Booking Summary</div>
                        <Row>
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon rnd">
                                <img
                                  className="star"
                                  src={bookedState.patientPictureUrl}
                                  alt="star"
                                />
                              </div>
                              <div className="txt">
                                <span>Patient name</span>
                                {bookedState.patientName}
                              </div>
                            </div>
                          </Col>
                          {bookedState.appointmentForId && (
                            <Col xs={12} sm={4}>
                              <div className="icon-text">
                                <div className="icon">
                                  <img
                                    className="star"
                                    src={dependent}
                                    alt="star"
                                  />
                                </div>
                                <div className="txt">
                                  <span>Depandant name</span>
                                  {bookedState.name}
                                </div>
                              </div>
                            </Col>
                          )}

                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={clk} alt="star" />
                              </div>
                              <div className="txt">
                                <span>Time</span>
                                {moment
                                  .utc(bookedState.scheduledAtUtc)
                                  .local()
                                  .format("hh:mm A")}
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={cal} alt="star" />
                              </div>
                              <div className="txt">
                                <span>Date</span>
                                {moment
                                  .utc(bookedState.scheduledAtUtc)
                                  .local()
                                  .format("DD, MMMM YY")}
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={4}>
                            <div className="icon-text">
                              <div className="icon rnddoc">
                                <img
                                  className="star"
                                  src={bookedState.doctorPictureUrl}
                                  alt="star"
                                />
                              </div>
                              <div className="txt">
                                <span>Doctor name</span>
                                {bookedState.doctorName}
                              </div>
                            </div>
                          </Col>

                          {bookedState.appointmentType !== "Treatment" && (
                            <Col xs={12} sm={4}>
                              <div className="icon-text">
                                <div className="icon">
                                  {bookedState.appointmentType === "InPerson" && (
                                    <img className="star" src={ip} alt="star" />
                                  )}
                                  {bookedState.appointmentType ===
                                    "VideoCall" && (
                                      <img className="star" src={vc} alt="star" />
                                    )}
                                  {bookedState.appointmentType ===
                                    "Treatment" && (
                                      <img className="star" src={star} alt="star" />
                                    )}
                                </div>
                                <div className="txt">
                                  <span>Appointment type</span>
                                  {bookedState.appointmentType === "InPerson" && (
                                    <>In Person</>
                                  )}
                                  {bookedState.appointmentType ===
                                    "VideoCall" && <>Video Call</>}
                                  {bookedState.appointmentType ===
                                    "Treatment" && <>Treatment</>}
                                </div>
                              </div>
                            </Col>
                          )}

                          {bookedState.treatment && (
                            <Col xs={12} sm={4}>
                              <div className="icon-text">
                                <div className="icon rnddoc">
                                  <img
                                    className="star"
                                    src={bookedState.treatment.pictureUrl}
                                    alt="star"
                                  />
                                </div>
                                <div className="txt">
                                  <span>Treatment Name</span>
                                  {bookedState.treatment.name}
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className="btn-tray">
                        <Link
                          to="/"
                          className="btnr btnr-primary-outline btnr-hover"
                        >
                          Go Back To Home <span className="arr"></span>
                        </Link>
                        <button
                          onClick={() => navigateToUploadReports(null)}
                          className="btnr btnr-primary btnr-hover"
                        >
                          Upload Documents <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bkgright">
                </div>

              </div>
            )}
          </div>
        </div>
        <div className={isPopupActive ? "payment-overlay" : "d-none"}></div>
        {isPopupActive && (
          <NewWindow
            url={paymentUrl}
            onUnload={() => setIsPopupActive(false)}
            features={{
              left: window.screenX + (window.outerWidth - 800) / 2,
              top: window.screenY + (window.outerHeight - 600) / 2.5,
              width: 800,
              height: 600,
            }}
          />
        )}
      </div>
    )
  );
}

export default BookAppointment;
