import axios from 'axios';
const rootApi = `${process.env.REACT_APP_API}/api/${process.env.REACT_APP_API_VERSION_V1}`;
// 18-03

const clinicId = process.env.REACT_APP_CLINIC_ID;
const oAuthTokenInstanceName = process.env.FIREBASE_PROJECTID;


export const PhonePeAPI = async (obj, token) => {
  try {
    const response = await axios.post(`${rootApi}/PhonePe/InitiateOrder`, obj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // Add any other necessary headers, if applicable
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error creating Razorpay order:", error);
    throw error; // Rethrow the error for further handling
  }
};

// export const PhonePeAPI = async (obj, token) => {
//   try {
//     const response = await fetch(`${rootApi}/PhonePe/InitiateOrder`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//         // Add any other necessary headers, if applicable
//       },
//       body: JSON.stringify(obj),
//     });

//     if (!response.ok) {
//       throw new Error("Failed to create Razorpay order");
//     }

//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error("Error creating Razorpay order:", error);
//     throw error; // Rethrow the error for further handling
//   }
// };

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayPhonePe(tokenUrl, userCancel, concluded) {
  console.log(tokenUrl, "MY URL");
  function callback(response) {
    if (response === "USER_CANCEL") {
      console.log("errr");

      userCancel();
      /* Add merchant's logic if they have any custom thing to trigger on UI after the transaction is cancelled by the user*/
      return;
    } else if (response === "CONCLUDED") {
      console.log("the user concluded");

      concluded();
      /* Add merchant's logic if they have any custom thing to trigger on UI after the transaction is in terminal state*/
      return;
    }
  }
  const res = await loadScript(
    "https://mercury.phonepe.com/web/bundle/checkout.js"
  );

  if (!res) {
    alert(
      "Failed to proceeed for payment. Please check your internet connection and try again."
    );
    return;
  }
  console.log(res);
  if (res) {
    window.PhonePeCheckout.transact({ tokenUrl, callback, type: "IFRAME" });
  }

  // const options = {
  //   key: customOptions.key, // Enter the Key ID generated from the Dashboard
  //   amount: customOptions.amount,
  //   currency: customOptions.currency,
  //   name: customOptions.name,
  //   description: "Clinic Payment",
  //   //image: { logo },
  //   order_id: customOptions.id,
  //   handler: async function (response) {
  //     const data = {
  //       orderCreationId: customOptions.order_id,
  //       razorpayPaymentId: response.razorpay_payment_id,
  //       razorpayOrderId: response.razorpay_order_id,
  //       razorpaySignature: response.razorpay_signature,
  //     };

  //     await callback(data);
  //   },
  //   prefill: {
  //     name: customOptions.name,
  //     email: customOptions.email,
  //     contact: customOptions.contact,
  //   },
  //   theme: {
  //     color: "#61dafb",
  //   },
  // };

  // const paymentObject = new window.Razorpay(options);

  // paymentObject.on("payment.failed", callOnError);

  // paymentObject.open();
}
