import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetDoctors = async (docObj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetDoctors/`,
    {
      clinicId,
      ...docObj,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const GetFilteredDoctors = async (docObj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetFilteredDoctors/`,
    {
      clinicId,
      ...docObj,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

export const GetDoctorsDetails = async (doctorId) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/doctor`,
    { slug: doctorId, clinicId: clinicId }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};


// export const GetDoctorsDetails = async (doctorId) => {
//   const promise = axios.get(
//     `/${process.env.REACT_APP_API_VERSION_V1}/doctor/${doctorId}`
//   );

//   const dataPromise = promise.then((response) => response.data.response);

//   return dataPromise;
// };
